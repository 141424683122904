import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import {Observable, ReplaySubject} from "rxjs";
import {CookieService} from "ngx-cookie-service";
import {ROUTES} from "../app-routes";

export enum AUTH_STATE {
  Unauthorized = 0,
  Authorized = 1,
  Pending = 2
}

@Injectable()
export class AuthorizationService {
  private readonly scope: string[] = ['guilds', 'guilds.members.read'];
  private readonly redirectUri: string = `${window.location.href}${ROUTES.AUTHORIZER}`;

  public authContext: Window | null = null;
  private accessToken: string | null = null;

  private authState$: ReplaySubject<AUTH_STATE> = new ReplaySubject<AUTH_STATE>(1);

  constructor(private cookieService: CookieService) {
    if(this.cookieService.check('accessToken')){
      this.accessToken = this.cookieService.get('accessToken');
      this.authState$.next(AUTH_STATE.Authorized);
    }else{
      this.authState$.next(AUTH_STATE.Unauthorized);
    }
  }

  public authorize(implicitRequest: boolean) {
    if(this.accessToken != null) return;
    this.authState$.next(AUTH_STATE.Pending);

    if (implicitRequest) {
      this.getImplicitGrant();
    } else {
      throw 'Non-implicit grants are not supported.'
    }
  }

  public getAuthState(): Observable<AUTH_STATE> {
    return this.authState$.asObservable();
  }

  private getImplicitGrant() {
    // If authContext already exists, refocus it
    if(this.authContext && !this.authContext.closed){
      this.authContext.focus();
      return;
    }

    const authUrl = AuthorizationService.generateAuthURL(environment.clientId, this.scope, this.redirectUri, false);
    this.createAuthWindow(authUrl);

    if (!this.authContext) {
      this.authState$.next(AUTH_STATE.Unauthorized);
      throw 'Error opening authorization window.'
    }

    this.monitorAuthWindow(this.authContext);
    this.authContext.focus();
  }

  createAuthWindow(authURL: URL){
    this.authContext = window.open(
      authURL.href,
      'Authorization',
      'toolbar=no,menubar=no,width=480,height=720,left=0,top=0');
  }


  /**
   * This is the standard way of monitoring a child window
   */
  monitorAuthWindow(authContext: Window){
    window.setInterval(() => {
      try{
        const href = authContext.location.href;
        this.accessToken = AuthorizationService.extractAccessTokenFromURL(href);
        this.cookieService.set('accessToken', this.accessToken, {
          secure: true,
          // TODO Do we want an expiration date?
          //  If not set, the cookie gets deleted after closing the browser
        });
        this.authState$.next(AUTH_STATE.Authorized);
        authContext.close();
        window.clearInterval();
      }catch (err){
        // Getting the href did not succeed.
        // Still waiting to get redirected back.
      }

      if((!this.authContext || this.authContext.closed) && !this.accessToken)
        this.authState$.next(AUTH_STATE.Unauthorized);
    }, 1000);
  }

  private static getRequestNonce(): string {
    const array: Uint32Array = new Uint32Array(2);
    return crypto.getRandomValues(array).toString();
  }

  private static extractAccessTokenFromURL(url: string): string {
    if (!url.match('access_token'))
      throw 'Access token unavailable.';

    return AuthorizationService.getQueryString('access_token', url) ?? '';
  }

  private static getQueryString(field: unknown, url: string) {
    const reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
    const string = reg.exec(url);
    return string ? string[1] : null;
  };

  private static generateAuthURL(clientID: string, scope: string[], redirectURI: string, statefull: boolean) {
    const authURL = new URL('https://discord.com/api/oauth2/authorize');
    authURL.searchParams.set('response_type', 'token');
    authURL.searchParams.set('client_id', clientID);
    authURL.searchParams.set('scope', scope.join(' '));
    authURL.searchParams.set('redirect_uri', redirectURI);

    if(statefull)
      authURL.searchParams.set('state', AuthorizationService.getRequestNonce())

    return authURL;
  }
}
