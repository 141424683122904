import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuoteCardComponent } from './quote-card/quote-card.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import {RouterModule} from "@angular/router";
import { LoginButtonComponent } from './login/login-button.component';


@NgModule({
  declarations: [
    QuoteCardComponent,
    NavbarComponent,
    FooterComponent,
    LoginButtonComponent,
  ],
  imports: [CommonModule, RouterModule],
  exports: [QuoteCardComponent, NavbarComponent, FooterComponent],
})
export class SharedModule {}
