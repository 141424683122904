import {ProfileModel} from "./profile.model";
import {QuoteEditFormInterface} from "../feature/quote-edit/quote-edit.component";

export class QuoteModel {
  constructor(public id: number,
              public quote: string,
              public source: string,
              public subject: ProfileModel,
              public reporter: ProfileModel,
              public date: Date,
              public likes: number,
              public dislikes: number,
              public context?: string,) {
  }

  patchValues(editQuoteModel: QuoteEditFormInterface) {
    this.context = editQuoteModel.context ?? '';
    this.quote = editQuoteModel.quote ?? '';
    this.source = editQuoteModel.source ?? '';
    this.reporter.username = editQuoteModel.reporter ?? '';
    this.subject.username = editQuoteModel.subject ?? '';
  }
}
