<div class="quote-card card">

  <!-- THREE DOTS MENU -->
  <div class="menu dropend">
    <div class="btn-icon dropdown-toggle" type="button" id="quote-menu-button" data-bs-toggle="dropdown">
      <i class="bi bi-three-dots fs-5 "></i>
    </div>
    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="quote-menu-button">
      <li><a class="dropdown-item" (click)="edit()">Edit</a></li>
      <li><a class="dropdown-item" (click)="delete()">Delete</a></li>
      <li><a class="dropdown-item text-danger" (click)="report()">Report</a></li>
    </ul>
  </div>

  <!-- VOTING -->
  <div class="voting card-body d-flex flex-column align-items-center">
    <div class="btn-icon mb-2" (click)="like()"><i class="bi bi-arrow-up-circle-fill fs-4"></i></div>
    <div class="btn-icon mb-1" (click)="dislike()"><i class="bi bi-arrow-down-circle fs-4"></i></div>
    <div class="small text-nowrap">{{ getVoteCount() }} votes</div>
  </div>

  <!-- QUOTE -->
  <div class="card-body d-flex flex-column align-items-center justify-content-center">
    <div *ngIf="quoteModel?.context" class="context small text-left text-muted w-100">
      {{ quoteModel?.context }}
    </div>
    <blockquote class="blockquote mb-0 me-3">
      <p class="mt-2">{{ quoteModel?.quote }}</p>
      <footer class="blockquote-footer">
        {{ quoteModel?.source }}
      </footer>
    </blockquote>
  </div>

  <!-- FOOTER -->
  <div class="card-footer text-muted small d-flex align-items-center">
    <button class="fav-btn btn btn-sm text-muted" (click)="favor()"><i class="bi bi-heart-fill"></i> Add to Favorite</button>
    <span class="flex-fill"></span>
    <span>{{getTimeString()}}</span>
    <span class="mx-1">•</span>
    <span class="fst-italic">{{ quoteModel?.reporter?.username }}</span>
    <img [src]="quoteModel?.reporter?.imageUrl" class="rounded-circle ms-2" alt="">
  </div>
</div>
