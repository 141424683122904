import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuoteEditComponent } from './quote-edit.component';
import {SharedModule} from "../../shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [QuoteEditComponent],
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule, RouterModule],
})
export class QuoteEditModule {}
