import { Component, Input } from '@angular/core';
import { QuoteModel } from "../../model/quote.model";

@Component({
  selector: 'quotely-quote-card',
  templateUrl: './quote-card.component.html',
})
export class QuoteCardComponent {
  @Input() quoteModel: QuoteModel | undefined;

  getVoteCount(): number {
    return this.quoteModel ? this.quoteModel.likes + this.quoteModel.dislikes : 0;
  }

  getTimeString(): string {
    if (!this.quoteModel) return 'Invalid Date';
    const millisecondsInThePast: number = Date.now()- this.quoteModel.date.getTime()

    const daysInThePast: number = new Date(millisecondsInThePast).getDay();
    if (daysInThePast < 30) return daysInThePast == 1 ? '1 day ago' : `${daysInThePast} days ago`;

    const hoursInThePast: number = new Date(millisecondsInThePast).getHours();
    if (hoursInThePast < 60) return hoursInThePast == 1 ? '1 hour ago' : `${hoursInThePast} hours ago`;

    const minutesInThePast: number = new Date(millisecondsInThePast).getMinutes();
    if (minutesInThePast < 60) return minutesInThePast == 1 ? '1 minute ago' : `${minutesInThePast} minutes ago`;

    const secondsInThePast: number = new Date(millisecondsInThePast).getSeconds();
    if (secondsInThePast < 60) return secondsInThePast == 1 ? '1 second ago' : `${secondsInThePast} seconds ago`;

    return 'just now'
  }

  edit() {
    console.error('Not Implemented');
  }

  delete() {
    console.error('Not Implemented');
  }

  report() {
    console.error('Not Implemented');
  }

  like() {
    console.error('Not Implemented');
  }

  dislike() {
    console.error('Not Implemented');
  }

  favor() {
    console.error('Not Implemented');
  }
}
