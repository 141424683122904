<nav class="navbar navbar-expand-lg py-3" [ngClass]="{'navbar-dark bg-primary': isColored, 'navbar-light': !isColored}">
  <div class="container">
    <a class="navbar-brand fw-bolder h1 mb-0" routerLink="">
      <img alt='quotely-logo' class="img-sm" [src]="isColored ? 'assets/logo-white.svg' : 'assets/logo-black.svg'">
      Quotely
    </a>

    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" href="#">Quickstart</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Features</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Documentation</a>
        </li>
      </ul>
    </div>

    <div class="flex-fill"></div>

    <quotely-login-button [isColored]="isColored"></quotely-login-button>

    <button class="navbar-toggler ms-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

  </div>
</nav>
