import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import {SharedModule} from "../../shared/shared.module";
import {HeaderComponent} from "./header/header.component";

@NgModule({
  declarations: [HomeComponent, HeaderComponent],
  imports: [CommonModule, SharedModule],
  exports: [HomeComponent]
})
export class HomeModule {}
