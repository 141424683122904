<div class="header w-100 bg-primary">
  <div class="container h-100 d-flex flex-column justify-content-center align-items-center">
    <div class="fw-bolder display-4 text-white text-center text-uppercase mb-4">None shall <br> be forgotten.</div>
    <div class="fs-5 text-white mb-3">Imagine a place where you can collect all the quotes.</div>
    <div class="w-100 d-flex justify-content-center">
      <button class="btn btn-outline-light shadow-hover">Add Quotely to your Discord server</button>
    </div>
  </div>
</div>
<div class="wave-divider">
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
  </svg>
</div>
