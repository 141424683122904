import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import {HomeComponent} from "./feature/home/home.component";
import {QuoteEditComponent} from "./feature/quote-edit/quote-edit.component";
import {ROUTES} from "./app-routes";
import {NotFoundComponent} from "./feature/not-found/not-found.component";
import {AuthorizerComponent} from "./feature/authorizer/authorizer.component";

const routes: Routes = [
  { path: ROUTES.HOME, component: HomeComponent },
  { path: ROUTES.QUOTE_EDIT, component: QuoteEditComponent },
  { path: ROUTES.NOT_FOUND, component: NotFoundComponent },
  { path: ROUTES.AUTHORIZER, component: AuthorizerComponent},
  { path: '**', redirectTo: ROUTES.NOT_FOUND },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
