import { Injectable } from '@angular/core';
import {QuoteModel} from "../model/quote.model";
import {ProfileModel} from "../model/profile.model";

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  mockedDatabase: QuoteModel[] = [
    new QuoteModel(
      0,
      'Not gonna lie, der Typ ist echt besser als ich... erwartet habe.',
      'Stephan Bescheidl',
      new ProfileModel('Stevethebeast', 'https://picsum.photos/20'),
      new ProfileModel('Hyperflip', 'https://picsum.photos/20'),
      new Date(2022, 7, 24),
      4,
      1)
  ]

  getOne(id: number): QuoteModel {
    return this.mockedDatabase[id];
  }

  getAll(): QuoteModel[] {
    return this.mockedDatabase;
  }

  update(quoteModel: QuoteModel): QuoteModel {
    this.mockedDatabase[quoteModel.id] = quoteModel;
    return quoteModel;
  }

  delete(id: number) {
    throw 'Not Implemented';
  }
}
