import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';
import { AuthorizationService } from "./authorization.service";
import {DatabaseService} from "./database.service";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    AuthorizationService,
    DatabaseService
  ]
})
export class CoreModule { }
