import {Component, OnInit} from '@angular/core';
import {AUTH_STATE, AuthorizationService} from "../../core/authorization.service";

@Component({
  selector: 'quotely-authorizer',
  templateUrl: './authorizer.component.html'
})
export class AuthorizerComponent implements OnInit {

  authorized = false;

  constructor(private authService: AuthorizationService) {
    // TODO Check if this component was called through the discord redirect,
    //  or manually by the user. If it was called manually, then we need to
    //  redirect the user back to the front page.
  }

  ngOnInit(): void {
    this.authService.getAuthState().subscribe((authState) => {
      this.authorized = authState === AUTH_STATE.Authorized;
    })
  }
}
