import {NgModule} from "@angular/core";
import {AuthorizerComponent} from "./authorizer.component";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../shared/shared.module";

@NgModule({
  declarations: [AuthorizerComponent],
  imports: [CommonModule, SharedModule],
  exports: [AuthorizerComponent]
})
export class AuthorizerModule {}
