<div class="container-fluid bg-dark py-5">
  <div class="container d-flex flex-column flex-md-row justify-content-between align-items-start">
    <div class="h2 text-white fw-bold d-flex">
      <img alt="quotely-logo" class="img-sm me-1" src="assets/logo-white.svg">
      Quotely
    </div>
    <div class="text-white lh-lg">
      <div class="text-primary fw-bold">Produkt</div>
      <ul class="list-unstyled">
        <li>Quickstart</li>
        <li>Features</li>
        <li>Status</li>
      </ul>
    </div>
    <div class="text-white lh-lg">
      <div class="text-primary fw-bold">Information</div>
      <ul class="list-unstyled">
        <li>Über</li>
        <li>Support</li>
        <li>Sicherheit</li>
        <li>Entwickler</li>
        <li>Spenden</li>
      </ul>
    </div>
    <div class="text-white lh-lg">
      <div class="text-primary fw-bold">Richtlinien</div>
      <ul class="list-unstyled">
        <li>Nutzungsbedingungen</li>
        <li>Datenschutz</li>
        <li>Cookie-Einstellungen</li>
        <li>Danksagungen</li>
      </ul>
    </div>
  </div>
</div>
