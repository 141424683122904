import {Component, OnInit} from '@angular/core';
import {QuoteModel} from "../../model/quote.model";
import { FormControl, FormGroup, Validators} from "@angular/forms";
import {DatabaseService} from "../../core/database.service";
import {ActivatedRoute} from "@angular/router";

export interface QuoteEditFormInterface {
  context: any,
  quote: any,
  source: any,
  subject: any,
  reporter: any
}

@Component({
  selector: 'quotely-quote-edit',
  templateUrl: './quote-edit.component.html',
})
export class QuoteEditComponent implements OnInit{
  constructor(private databaseService: DatabaseService, private activatedRoute: ActivatedRoute) {
  }

  quoteModel: undefined | QuoteModel;

  controls: QuoteEditFormInterface = {
    context: new FormControl('', [Validators.minLength(3), Validators.maxLength(250)]),
    quote: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(250)]),
    source: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(250)]),
    subject: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(250)]),
    reporter: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(250)]),
  }

  formGroup = new FormGroup(this.controls)

  errorWasThrown = false;

  ngOnInit(): void {
    const quoteId = parseInt(this.activatedRoute.snapshot.params['id']);
    if (isNaN(quoteId)) {
      this.errorWasThrown = true;
      return;
    }

    this.quoteModel = this.databaseService.getOne(quoteId);
    if (this.quoteModel == undefined) {
      this.errorWasThrown = true;
      return;
    }

    this.formGroup.patchValue({
      context: this.quoteModel.context,
      quote: this.quoteModel.quote,
      source: this.quoteModel.source,
      subject: this.quoteModel.subject.username,
      reporter: this.quoteModel.reporter.username
    } as QuoteEditFormInterface)

    this.formGroup.valueChanges.subscribe(values => {
      this.quoteModel?.patchValues(values as QuoteEditFormInterface);
    })
  }
}
