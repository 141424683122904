import {Component, Input, OnInit} from '@angular/core';
import {AUTH_STATE, AuthorizationService} from "../../core/authorization.service";

@Component({
  selector: 'quotely-login-button',
  templateUrl: './login-button.component.html'
})
export class LoginButtonComponent implements OnInit {

  @Input()
  isColored = false;

  authState: AUTH_STATE = AUTH_STATE.Unauthorized;

  authorized = false;
  pending = false;

  constructor(private auth: AuthorizationService) {}

  ngOnInit() {
    this.auth.getAuthState().subscribe(state => {
      this.authorized = state === AUTH_STATE.Authorized;
      this.pending = state === AUTH_STATE.Pending;
    })
  }

  onLoginClicked() {
    this.auth.authorize(true);
  }

}
