<div class="container grid align-items-center">
  <div *ngIf="errorWasThrown" class="g-col-12 text-center">
    <div class="display-1 fw-bolder">Error</div>
    <div class="h3 fw-bold">Something went wrong</div>
    <button class="btn btn-dark mt-3" routerLink="">Go Home</button>
  </div>

  <form *ngIf="quoteModel" [formGroup]="formGroup" class="g-col-12 g-col-md-6">
    <div class="h4 fw-bold">Edit Quote</div>
    <!-- CONTEXT TEXTAREA -->
    <div class="mb-3">
      <label for="contextTextarea" class="form-label">Context</label>
      <textarea [formControl]="controls.context" type="email" class="form-control" id="contextTextarea" rows="3" [ngClass]="{'is-valid': controls.context.valid, 'is-invalid': !controls.context.valid}"></textarea>
      <div class="valid-feedback">
        Looks good!
      </div>
      <div class="invalid-feedback">
        Please enter a context.
      </div>
    </div>

    <!-- QUOTE INPUT -->
    <div class="mb-3">
      <label for="quoteInput" class="form-label">Quote</label>
      <input [formControl]="controls.quote" type="text" class="form-control" id="quoteInput" [ngClass]="{'is-valid': controls.quote.valid, 'is-invalid': !controls.quote.valid}">
      <div class="valid-feedback">
        Looks good!
      </div>
      <div class="invalid-feedback">
        Please enter a quote.
      </div>
    </div>

    <!-- SOURCE INPUT -->
    <div class="mb-3">
      <label for="sourceInput" class="form-label">Source</label>
      <input [formControl]="controls.source" type="text" class="form-control" id="sourceInput" [ngClass]="{'is-valid': controls.source.valid, 'is-invalid': !controls.source.valid}">
      <div class="valid-feedback">
        Looks good!
      </div>
      <div class="invalid-feedback">
        Please enter a source.
      </div>
    </div>

    <div class="grid">
      <!-- SUBJECT DATALIST -->
      <div class="g-col-6">
        <label for="subjectDatalist" class="form-label">Subject Discord Account</label>
        <input [formControl]="controls.subject" class="form-control" list="subjectDatalistOptions" id="subjectDatalist" placeholder="Type to search..." [ngClass]="{'is-valid': controls.subject.valid, 'is-invalid': !controls.subject.valid}">
        <datalist id="subjectDatalistOptions">
          <option>Hyperflip</option>
          <option>Fredi100</option>
          <option>Ewandos</option>
        </datalist>
        <div class="valid-feedback">
          Looks good!
        </div>
        <div class="invalid-feedback">
          Please enter a subject username.
        </div>
      </div>

      <!-- REPORTER DATALIST -->
      <div class="g-col-6">
        <label for="reporterDatalist" class="form-label">Reporter Discord Account</label>
        <input [formControl]="controls.reporter" class="form-control" list="reporterDatalistOptions" id="reporterDatalist" placeholder="Type to search..." [ngClass]="{'is-valid': controls.reporter.valid, 'is-invalid': !controls.reporter.valid}">
        <datalist id="reporterDatalistOptions">
          <option>Hyperflip</option>
          <option>Fredi100</option>
          <option>Ewandos</option>
        </datalist>
        <div class="valid-feedback">
          Looks good!
        </div>
        <div class="invalid-feedback">
          Please enter a reporter username.
        </div>
      </div>
    </div>

    <button [disabled]="!formGroup.valid" type="submit" class="btn btn-primary mt-4">Submit</button>
  </form>

  <quotely-quote-card *ngIf="quoteModel" class="g-col-12 g-col-md-6" [quoteModel]="quoteModel"></quotely-quote-card>

</div>
