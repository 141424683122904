import {Component, OnInit} from '@angular/core';
import {NavigationStart, Router, Event as NavigationEvent} from "@angular/router";
import {ROUTES} from "../../app-routes";

@Component({
  selector: 'quotely-navbar',
  templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit {
  constructor(private router: Router) {
  }

  isColored = false;
  pathsWhereNavbarIsColored = [
    ROUTES.HOME
  ]

  ngOnInit(): void {
    this.router.events
      .subscribe(
        (event: NavigationEvent) => {
          if (event instanceof NavigationStart) {
            this.isColored = this.pathsWhereNavbarIsColored.includes(event.url.substring(1));
          }
        });
  }

}
