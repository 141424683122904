import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HomeModule} from "./home/home.module";
import {QuoteEditModule} from "./quote-edit/quote-edit.module";
import {NotFoundModule} from "./not-found/not-found.module";
import {AuthorizerModule} from "./authorizer/authorizer.module";



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HomeModule,
    QuoteEditModule,
    AuthorizerModule
  ],
  exports: [HomeModule, QuoteEditModule, NotFoundModule]
})
export class FeatureModule { }
